

















  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { CMS } from '@one/types'
  import FooterItem = CMS.Footer.FooterListItem;

  @Component
  export default class OneFooterListItem extends Vue {
    @Prop({
      type: Object,
    })
    item!: FooterItem;
  }
